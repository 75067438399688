<template>
    <div>        
        <div class="container">
            <div  width="100%">
                <div class="section">
                 <div style="display:flex;justify-content:flex-end;">
                    <div style="margin-top:-10px;"><el-image style="padding-top:15px;" :src="require('@/assets/img/legend.png')"></el-image></div>
                 </div>
                </div>
                <el-tag @click="onfilter(1)" :class="[flag==1?'tagSel':'tag']">全网联播</el-tag>
                <el-tag @click="onfilter(2)" :class="[flag==2?'tagSel':'tag']">全省联播</el-tag>
                <el-tag @click="onfilter(7)" :class="[flag==7?'tagSel':'tag']">特殊节点联播</el-tag>
                <el-tag @click="onfilter(0)" :class="[flag==0?'tagSel':'tag']">全部</el-tag>
                <el-row style="margin-top:5px;">
                    <!-- <product-table v-if="showTable" :data="GetValidStandardProductList"/> -->
                    <div class="card-group" style="display:flex;justify-content:space-around;"> 
                    <!-- GetValidStandardProductList -->
                                <div class="group-item" style="display:flex;align-items:center;" v-for="(item,groupIndex) in packageList" :key="groupIndex">
                                    <!-- {{groupIndex}} -->
                                    <div v-if="fromFeiBiao">
                                        <el-checkbox v-model="item.selected"></el-checkbox>
                                    </div>
                                    <div style="margin-left:5px; margin-top:5px;">
                                    <el-card class="box-card" >
                                        <!-- <div class="slash"><span>{{item.saleStatus}}</span></div> -->
                                        <!-- <div :class="item.saleStatus=='正常'?'slash ok':item.saleStatus=='未开始'?'slash ready':'slash delay'">
                                            <div>{{item.saleStatus}}</div>
                                        </div> -->
                                        <div v-if="item.saleStatus==='正常' && item.useRate<100" class="slash ok">
                                                  <div >{{item.saleStatus}}</div>
                                        </div>
                                        <div v-else-if="item.saleStatus==='正常' && item.useRate==100" class="slash empty">
                                            <div >无资源</div>
                                        </div>
                                        <div v-else :class="item.saleStatus=='未开始'?'slash ready':'slash delay'">
                                            <el-tooltip  class="item" effect="dark" placement="right" content="可协调，请咨询高铁资源中心。">      
                                                <div style="margin-top: -1px;">{{item.saleStatus}}</div>
                                            </el-tooltip>
                                        </div>

                                        <div>
                                            <div class="clearfix" style="height: 25px;">
                                                <el-button   class="product-name" style="margin-left:40px;" type="text" @click="onQueryDetail(item)">
                                                    <!-- <span v-if="item.name.length>18">
                                                        <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom-start">
                                                           {{item.name.substring(0,18)}}...
                                                        </el-tooltip>
                                                    </span>
                                                    <span v-else>
                                                        {{ item.name }}
                                                    </span> -->
                                                    {{item.name.length>18?item.name.substring(0,18)+'...':item.name}}

                                                </el-button>

                                                <!-- <span v-if="item.name.length>18">
                                                    <el-tooltip class="item" effect="dark" :content="item.name" placement="bottom-start">
                                                        <el-button   class="product-name" style="margin-left:40px;" type="text" @click="onQueryDetail(item)">
                                                            {{ item.name.substring(0,18) }}...
                                                        </el-button>
                                                    </el-tooltip>
                                                </span>
                                                <span v-else>
                                                    <el-button   class="product-name" style="margin-left:40px;" type="text" @click="onQueryDetail(item)">
                                                        {{ item.name}}
                                                    </el-button>
                                                </span> -->

                                                <el-tooltip  v-if="!fromFeiBiao" class="item" effect="dark" placement="right" content="加入购物车">                                                    
                                                    <el-button v-if="item.saleStatus=='正常' && item.useRate<100" style="float: right; padding: 0px 0;margin-top: -8px;font-size: 25px;" class="el-icon-shopping-cart-2" type="text" @click="onSetPlan(item)"></el-button>
                                                    <el-button  v-else style="float: right; padding: 0px 0;margin-top: -8px;font-size: 25px;color:grey;" class="el-icon-shopping-cart-2" type="text"></el-button>
                                                </el-tooltip>
                                            </div>
                                        
                                            <div  class="text item">                                            
                                                <div style="margin-top:20px;">
                                                    <el-row>
                                                        <el-col :span="6" class="subtitle">资源总量(个)</el-col>
                                                        <el-col :span="18">{{item.totalCount}}</el-col>
                                                    </el-row>
                                                    <!-- <el-row v-if="item.count">
                                                        <el-col :span="6" class="subtitle">资源限量(个)</el-col>
                                                        <el-col :span="18">{{item.count}}</el-col>
                                                    </el-row> -->

                                                    <!-- <el-row v-if="item.length">
                                                        <el-col :span="6" class="subtitle">版本时长(秒)</el-col>
                                                        <el-col :span="18">{{item.length}}</el-col>
                                                    </el-row>
                                                    <el-row v-if="item.times">
                                                        <el-col :span="6" class="subtitle">投放频次(次/天)</el-col>
                                                        <el-col :span="18">{{item.times}}</el-col>
                                                    </el-row> -->
                                                    <el-row v-if="item.groupLevel==2 || item.typeIds=='2'">
                                                        <el-col :span="6" class="subtitle">价格(万元)</el-col>
                                                        <el-col :span="18" style="color:red">￥{{item.cost}}</el-col>
                                                    </el-row>
                                                    <el-row v-if="item.groupLevel==1 && item.typeIds=='1'">
                                                        <el-col :span="6" class="subtitle">15秒价格(万元)</el-col>
                                                        <el-col :span="18" style="color:red">￥{{item.cost15}}</el-col>
                                                    </el-row>
                                                    <el-row v-if="item.groupLevel==1 && item.typeIds=='1'">
                                                        <el-col :span="6" class="subtitle">10秒价格(万元)</el-col>
                                                        <el-col :span="18" style="color:red">￥{{item.cost10}}</el-col>
                                                    </el-row>
                                                    <el-row v-if="item.groupLevel==1 && item.typeIds=='3'">
                                                        <el-col :span="6" class="subtitle">小轮播价格(万元)</el-col>
                                                        <el-col :span="18" style="color:red">￥{{item.cost15}}</el-col>
                                                    </el-row>
                                                    <el-row v-if="item.groupLevel==1 && item.typeIds=='3'">
                                                        <el-col :span="6" class="subtitle">大轮播价格(万元)</el-col>
                                                        <el-col :span="18" style="color:red">￥{{item.cost10}}</el-col>
                                                    </el-row>
                                                    <!-- <el-row v-if="item.discount">
                                                        <el-col :span="6" class="subtitle">折扣(折)</el-col>
                                                        <el-col :span="18" style="color:red">{{item.discount}}</el-col>
                                                    </el-row> -->
                                                    <el-row v-if="item.groupLevel==2">
                                                        <el-col :span="6" class="subtitle">售卖时期</el-col>
                                                        <el-col :span="18">{{item.saleDate}}</el-col>
                                                    </el-row>
                                                    <!-- <el-row v-if="item.groupLevel==2">
                                                        <el-col :span="6" class="subtitle">投放时期</el-col>
                                                        <el-col :span="17">{{item.playDate}}</el-col>                                                        
                                                    </el-row> -->
                                                    
                                                   <!-- v-if="item.expend" -->
                                                    <el-row >
                                                        <!-- 特　　征 -->
                                                        <el-col :span="6" class="subtitle">
                                                            <p>投放效果</p>
                                                            <p>({{item.staticMonth}})</p>
                                                        </el-col>
                                                        <el-col :span="18">
                                                            <!-- <p><el-col :span="12">CPM：</el-col><el-col :span="12">{{item.cpm}}</el-col></p> -->
                                                            <el-row><el-col :span="8">CPM：</el-col><el-col :span="16">{{item.cpm}}(元)</el-col></el-row>
                                                            <el-row><el-col :span="8">累计接触度：</el-col><el-col :span="16">{{item.frequency}}(千人次)</el-col></el-row>
                                                            <el-row><el-col :span="8">到达率：</el-col><el-col :span="16">{{item.chudarenshu}}(千人)</el-col></el-row>
                                                            <el-row><el-col :span="8">暴露频次：</el-col><el-col :span="16">{{item.reach}}(次)</el-col></el-row>
                                                            <!-- <p>累计接触度：{{item.frequency}}(千人次)</p>
                                                            <p>到达率：{{item.chudarenshu}}(千人)</p>
                                                            <p>暴露频次：{{item.reach}}(次)</p> -->
                                                        </el-col>
                                                    </el-row>
                                                    <!-- 特　　征 -->
                                                    <el-row v-if="item.tags">                                                    
                                                        <el-col :span="6" class="subtitle">特征</el-col>
                                                        <el-col :span="18">
                                                            <p v-html="item.tags.replace('\r\n','\n').split('\n').map(item => `<p>${item}</p>`).join('')"></p>
                                                        </el-col>
                                                    </el-row>
                                                    <el-row v-if="item.remark && item.collapse">
                                                        <span style="color:#3EA7FF;cursor:pointer;margin-left:400px;margin-top:-20px;" @click="item.collapse=!item.collapse" type="primary">展开</span>
                                                    </el-row>
                                                    <el-row v-if="item.remark">
                                                        <el-col v-if="!item.collapse" :span="6" class="subtitle"><span v-if="!item.collapse">说明</span></el-col>
                                                        <el-col :span="18">
                                                            <!-- <p v-html="item.remark.replace('\r\n','\n').split('\n').map(item => `<p>${item}</p>`).join('')"></p> -->
                                                            <!-- <div v-if="item.remark.length>20"> -->
                                                                <div v-if="!item.collapse">
                                                                    <span style="color:#3EA7FF;cursor:pointer;margin-left:300px;" @click="item.collapse=!item.collapse" type="primary">收起</span>                                                                
                                                                    <p v-html="item.remark.replace('\r\n','\n').split('\n').map(item => `<p>${item}</p>`).join('')"></p>                                                                    
                                                                </div>
                                                            <!-- </div>
                                                            <div v-else>
                                                                <p v-html="item.remark.replace('\r\n','\n').split('\n').map(item => `<p>${item}</p>`).join('')"></p>
                                                            </div> -->
                                                        </el-col>
                                                    </el-row>

                                                    <!-- <div><span class="subtitle">资源总量</span>{{item.totalCount}}</div>
                                                    <div v-if="item.count"><span class="subtitle">资源限量</span>{{item.count}}</div>
                                                    <div v-if="item.length"><span class="subtitle">版本时长</span>{{item.length}}</div>
                                                    <div v-if="item.times"><span class="subtitle">每日投放频次</span>{{item.times}}</div>
                                                    <div><span class="subtitle">价格</span><span style="color:red">￥{{item.cost}}万元</span></div>
                                                    <div><span class="subtitle">售卖时期</span>{{item.saleDate}}</div>
                                                    <div><span class="subtitle">投放时期</span>{{item.playDate}}</div>
                                                    <div><span class="subtitle">说明</span>{{item.remark}}</div> -->
                                                    
                                                    <!-- <div><span class="subtitle">是否限价</span>{{item.isFixedPrice==true?'是':'否'}}</div> -->
                                                    <!-- <div><span class="subtitle">创建人</span>{{item.userName}}</div> -->
                                                    
                                                </div>
                                                <el-row >
                                                    <el-col style="margin-top:5px;" :span="6" class="subtitle">媒体上刊率</el-col>
                                                    <el-col style="margin-top:5px;" :span="18"><el-progress style="width:340px;" :text-inside="true" :stroke-width="20" :percentage="item.useRate" 
                                                    :status="item.useRate>=80?'exception':item.useRate<80 && item.useRate>=50?'success':item.useRate<50 && item.useRate>=30?'':'warning'"></el-progress></el-col>
                                                </el-row>
                                                
                                                <div  v-if="fromFeiBiao" style="display:flex;justify-content:flex-end;margin-top:-20px;">
                                                    <el-switch v-model="item.isFree" active-text="赠送" inactive-text="非赠送"></el-switch>
                                                </div>
                                            </div>
                                         </div>                                         
                                    </el-card>
                                    </div>
                                    <el-dialog title="产品明细" v-model="setDetailDialogVisible" width="70%" @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        append-to-body>
                                        <product-detail @close="closeDetail" :cur-item="curItem" :table-data="tableData"/>
                                        <!-- :group-media-list="groupDetailMediaList" -->
                                    </el-dialog>

                                    <el-dialog title="设置投放计划" v-model="setPlanVisible" width="40%" @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        v-if="setPlanVisible"
                                        append-to-body>
                                        <set-plan @close="setPlanVisible=false" :s-date="sDate" :e-date="eDate" :times="times" :cur-item="curItem" :table-data="tableData"/>
                                        <!-- :group-media-list="groupDetailMediaList" -->
                                    </el-dialog>

                                    <el-dialog title="套餐明细" v-model="viewPackageVisible" width="40%" @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        append-to-body>
                                        <package-detail @close="viewPackageVisible=false" :package-data="packageMediaList"/>                    
                                    </el-dialog>


                                </div>
                            </div>
                  
                </el-row>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import SmartResult from '@/views/Business/SmartResult.vue';
import {SaveProjectSmart,ProjectExport} from "@/api/operate"
import {getToken} from "@/utils/auth";
import SelectMedia from '@/views/Business/SelectMedia.vue';
// import SelectProduct from './SelectProduct.vue';
import SelectMediaPackage from '@/views/Business/SelectMediaPackage.vue';
// import Product from '@/views/Business/Smart/Product';
// import ProductTable from '@/views/Business/Smart/ProductTable';
import Vue from 'vue';


import StandardProduct from '@/views/Business/Smart/StandardProduct';
// import Product from '@/views/Business/Smart/Product';
import {getImagePath} from "@/utils/auth";
import Custom from '@/views/Business/Smart/Custom';
import Suggest from '@/views/Business/Smart/Suggest';
import ProductDetail from '@/views/Business/Smart/ProductDetail';
import SetPlan from '@/views/Business/Smart/SetPlan';
import PackageDetail from '@/views/Business/Smart/PackageDetail';


export default {
    name: 'baseform',
    components:{SmartResult, SelectMedia,SelectMediaPackage,
        Custom,Suggest,StandardProduct,ProductDetail,SetPlan,PackageDetail},//,Product
    computed: {...mapGetters(['mediaTypeList','packageList','GetValidStandardProductList','GetGroupDetail','GetGroupList'
        ,'areaStationList','categoryList','GetPlanPrice','CalculateMultiSmart'
        ,'BuildCartItem_Multi','GetPackageMediaList'])},
    data() {
        return {
            showTable:false,

            transferData:'',
            //producttable子模块
            setDetailDialogVisible:false,   
            curItem:{},  
            groupMediaList:[],
            setPlanVisible:false,
            tableData:[],
            sDate:'',
            eDate:'',
            times:100,
            packageMediaList:[],
            viewPackageVisible:false,
            fromData:[],
            
            packageList:[],
            flag:0,
        };
    },
    
    async mounted() {
        const s = document.createElement('script');
        s.type = 'text/javascript';
        s.src = 'http://pv.sohu.com/cityjson?ie=utf-8&ver='+Math.random();
        document.body.appendChild(s);
 
        this.onProductQuery();
    },

    methods: {        
        async onProductQuery(){
            await this.$store.dispatch('operate/GetValidStandardProductList',{stationIds:'',packageIds:'',key:'',isValid:1}).then(()=>{
                // this.packageList = this.GetValidStandardProductList;
                this.packageList = this.GetValidStandardProductList;//.filter(item=>item.packageIds==0);
            })
            
        },

        onfilter(flag){
            console.log('guolv')
            this.flag=flag;
            if (flag==0){
                this.packageList = this.GetValidStandardProductList;
            }else{
                this.packageList = this.GetValidStandardProductList.filter(item=>item.packageIds==flag);
            }
        },
       
        async onQueryDetail(item){
        //    
           if (item.groupLevel==2){
               this.curItem=item;
               
                await this.$store.dispatch('operate/GetGroupDetail',{groupId:this.curItem.groupId}).then(()=>{   
                    this.tableData=this.GetGroupDetail[1];   
                    this.setDetailDialogVisible=true;       
                })   
           }else{
               await this.$store.dispatch('operate/GetPackageMediaList',{groupId:item.groupId}).then(()=>{   
                var obj=[];
                var path = getImagePath();
                // console.log('aa',this.GetPackageMediaList)
                this.GetPackageMediaList[1].forEach(element => {
                    var imgObj=[];
                    if (element.images){
                        var imgArr=element.images.split(';');                    
                        if (imgArr && imgArr.length>0){
                            imgArr.forEach(ele2 => {
                                imgObj.push(path+ele2);
                            });                
                        }
                    }                    
                    element.imgList=imgObj;
                    obj.push(element);
                });
                this.packageMediaList = obj;
                // console.log('aa',this.packageMediaList)
                this.viewPackageVisible=true;      
            })   
           }


            // await this.$store.dispatch('operate/GetGroupDetail',{groupId:item.groupId}).then(()=>{   
            //     console.log('item',this.GetGroupDetail[1])
            //     this.groupMediaList=this.GetGroupDetail[1];  
            //     // this.prodCostByMedia = this.GetGroupDetail[2][0].costs;
                

            // })   
             
        },

        closeDetail(){
            
            this.setDetailDialogVisible=false;      
        },

        open(){
            // console.log('do open')
            this.$nextTick(() => {
            //  执行echarts方法
            
            })
        },

        async onSetPlan(item){

            // console.log('itemitemitemitem',item)
            this.curItem=item;
            

            if (this.condition && item.groupLevel==1){  //已传入条件，直接加入购物车
                //构造购物车数据
                const {sDate,eDate,times,length,isFree}=this.condition;
                this.sDate=sDate;
                this.eDate=eDate;
                this.times=times;
                var groupId = item.groupId;

                const isAdded = this.$store.state.cart.cartList.find(item => item.groupId === groupId && item.item.sDate === sDate && item.item.eDate === eDate);
                console.log('isAdded',isAdded,this.curItem.groupId,sDate,eDate)
                if(isAdded){
                    this.$message({message: '已加购该时段的资源，请勿重复加购',type: 'error'});
                    return;
                }
                
                await this.$store.dispatch('operate/BuildCartItem_Multi',{sDate,eDate,times,length,groupIds:groupId+'|'+ele.isFree}).then(()=>{   
                    this.BuildCartItem_Multi.forEach(element => {
                        this.$store.dispatch('cart/addCart', {selItem:element});                        
                    });
                    this.$message({message: '成功加入购物车！',type: 'success'});
                    
                })  

            }else{
                await this.$store.dispatch('operate/GetGroupDetail',{groupId:this.curItem.groupId}).then(()=>{   
                    this.tableData=this.GetGroupDetail[1];   
                    if (this.condition){
                        const {sDate,eDate,times,length,isFree}=this.condition;
                        this.sDate=sDate;
                        this.eDate=eDate;
                        this.times=times;
                    }
                    // console.log('bbbb',this.sDate,this.eDate,this.times)
                    this.setPlanVisible=true;       
                })  
            }
             
          
        },

        closeSetPlan(){
            this.setPlanVisible=false; 
        },
    }
};
</script>

<style scoped>

.slash{
    width:100px;
    height:1px;    
    transform:rotate(-40deg);
    margin-top: -15px;
    margin-left: -45px;
    /* display:flex;
    justify-content: center;
    align-items: center; */
   
}
.tag{
    cursor: pointer;margin-left:15px;
    font-size: larger;
}
.tagSel{
    cursor: pointer;margin-left:15px;
    font-size:x-large;
    background-color: #409EFF;
    color: white;
}

.ok{
    border-bottom:30px solid green;
}

.delay{
    border-bottom:30px solid red;
}

.ready{
    border-bottom:30px solid #999;
}

.empty{
    border-bottom:30px solid #999;
}

.slash div{
    margin-left:30px;
    padding-top: 7px;
}


.sub-title{
    font-size: large;
    margin-top:4px;
}
.item-pre{
    font-size: larger;
}
.button-footer{
    display: flex;
    flex-direction:row-reverse;
}
.product-name{
    font-size: x-large;
    /* margin-left:25px; */
    margin-top:-20px;
}
.card-group{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.group-item{
    padding: 1px;
    margin-left: 1px;
    
}
.box-card {
    width: 480px;
    
  }
   .text {
    font-size: 14px;
  }

  .item {
    /* margin-bottom: 18px; */
    margin-top:18px;
  }

  /* .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  } */
 

.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

.schart-box {
    display: inline-block;
    margin: 20px;
}
.schart {
    width: 600px;
    height: 400px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}


.grid-content {
    display: flex;
    align-items: center;
    flex-direction:column;
    /* height: 120px; */
}

.grid-subcontent {
    display: flex;
    align-items: center;
    height: 50px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 15px;
    /* font-weight: bold; */
}

.grid-num-small {
    font-size: 16px;
    font-weight: bold;
}

.grid-subnum {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    border-radius: 50%;
    /* margin-left: 80px; */
}
.grid-subcon-icon{
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.grid-con-0 .grid-subcon-icon {
    background: rgb(184, 223, 223);
}

.grid-con-0 .grid-num {
    color: rgb(184, 223, 223);
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.grid-con-4 .grid-con-icon {
    background: rgb(132, 94, 67);
}

.grid-con-4 .grid-num {
    color: rgb(132, 94, 67);
}

.grid-con-5 .grid-con-icon {
    background: rgb(66, 94, 67);
}

.grid-con-5 .grid-num {
    color: rgb(66, 94, 67);
}

.grid-con-6 .grid-con-icon {
    background: rgb(66, 136, 67);
}

.grid-con-6 .grid-num {
    color: rgb(66, 136, 67);
}

.clearfix{
    margin-top: -5px;
}
.subtitle{
    font-weight: bolder;
    padding-right: 5px;
}


</style>