<template>
    <!-- <el-dialog title="选择销售" v-model="visible" :before-close="beforeClose"> -->
                <!-- <el-dialog title="产品明细" v-model="setDetailDialogVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body> -->
        <div style="margin-top:-50px;">
            <el-divider ></el-divider>
                <div style="margin-left:10px;">       
                    <el-row style="display:flex;justify-content:flex-end;">
                        <el-tooltip class="item" effect="dark" placement="right" content="加入购物车">
                            <el-button style="float: right; padding: 0px 0;margin-top: -8px;font-size: 25px;" class="el-icon-shopping-cart-2" type="text" @click="onSetPlan(item)"></el-button>
                        </el-tooltip>
                    </el-row>
                    <el-row style="margin-top:-20px;">
                        <!-- <el-col :span="8">
                            <div style="display:flex;justify-content:center">
                                <el-image :src="curItem.img" style="width: 350px; height: 250px" :fit="'fill'">
                                    <div class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                </el-image>
                            </div>
                        </el-col> -->
                        <el-col :span="1"></el-col>
                        <el-col :span="23">
                        <el-row>
                            <el-col :span="6"><span class="subtitle">产品包名称</span></el-col>
                            <el-col :span="18">{{curItem.name}}</el-col>
                        </el-row>    
                        <el-row>
                            <el-col :span="6"><span class="subtitle">资源数量(个)</span></el-col>
                            <el-col :span="18">{{curItem.totalCount}}</el-col>
                        </el-row> 
                        <el-row  v-if="curItem.length">
                            <el-col :span="6"><span class="subtitle">版本时长(秒)</span></el-col>
                            <el-col :span="18">{{curItem.length}}</el-col>
                        </el-row> 
                        <el-row v-if="curItem.times">
                            <el-col :span="6"><span class="subtitle">投放频次(次)</span></el-col>
                            <el-col :span="18">每日{{curItem.times}}</el-col>
                        </el-row> 
                        <el-row>
                            <el-col :span="6"><span class="subtitle">售卖时期</span></el-col>
                            <el-col :span="18">{{curItem.saleDate}}</el-col>
                        </el-row> 
                        <el-row>
                            <el-col :span="6"><span class="subtitle">投放时期</span></el-col>
                            <el-col :span="18">{{curItem.playDate}}</el-col>
                        </el-row> 
                        <el-row>
                            <el-col :span="6"><span class="subtitle">产品价格(万元)</span></el-col>
                            <el-col :span="18" style="color:red">{{curItem.cost}}</el-col>
                        </el-row> 
                        <!-- <el-row v-if="curItem.discount">
                            <el-col :span="6" class="subtitle">折扣(折)</el-col>
                            <el-col :span="18" style="color:red">{{curItem.discount}}</el-col>
                        </el-row> -->
                        <el-row>
                            <!-- 特　　征 -->
                            <el-col :span="6" class="subtitle">
                                <p>投放效果</p>
                                <p>({{curItem.staticMonth}})</p>
                            </el-col>
                            <el-col :span="18">
                                <!-- <p>CPM(元)：{{curItem.cpm}}</p>
                                <p>累计接触度：{{curItem.frequency}}(千人次)</p>
                                <p>到达率：{{curItem.chudarenshu}}(千人)</p>
                                <p>暴露频次：{{curItem.reach}}(次)</p> -->
                                <el-row><el-col :span="6">CPM：</el-col><el-col :span="18">{{curItem.cpm}}(元)</el-col></el-row>
                                <el-row><el-col :span="6">累计接触度：</el-col><el-col :span="18">{{curItem.frequency}}(千人次)</el-col></el-row>
                                <el-row><el-col :span="6">到达率：</el-col><el-col :span="18">{{curItem.chudarenshu}}(千人)</el-col></el-row>
                                <el-row><el-col :span="6">暴露频次：</el-col><el-col :span="18">{{curItem.reach}}(次)</el-col></el-row>
                            </el-col>
                        </el-row>
                        <el-row>
                            <el-col :span="6"><span class="subtitle">说　　明</span></el-col>
                            <el-col :span="18">
                                <p v-if="curItem.remark" v-html="curItem.remark.replace('\r\n','\n').split('\n').map(item => `<p>${item}</p>`).join('')"></p>
                            </el-col>
                        </el-row>     
                        </el-col>
                    </el-row>
                    <el-row>       
                        <!-- <div><span class="subtitle">产品包名称</span>{{curItem.name}}</div>
                        <div><span class="subtitle">资源数量</span>{{curItem.totalCount}}</div>
                        <div v-if="curItem.length"><span class="subtitle">版本时长(秒)</span>{{curItem.length}}</div>
                        <div v-if="curItem.times"><span class="subtitle">每日投放频次</span>{{curItem.times}}</div>
                        <div><span class="subtitle">售卖时期</span>{{curItem.saleDate}}</div>
                        <div><span class="subtitle">投放时期</span>{{curItem.playDate}}</div>
                        <div><span class="subtitle">产品价格(万元)</span>{{curItem.cost}}</div>
     
                        <div><span class="subtitle">说明</span>{{curItem.remark}}</div> -->
                        <el-table
                            :data="tableData.slice((productGroup.currentPage-1)*productGroup.pageSize,productGroup.currentPage*productGroup.pageSize)"
                            border
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            style="width: 100%;margin-top: 20px;font-size: x-small;">		
                            <el-table-column prop="station" align="center" sortable label="车站">
                                <template v-slot="scope">
                                    <!-- <el-button type="text" @click="openStation(scope.row.station)">{{scope.row.station}}</el-button>    -->
                                    <span style="cursor:pointer;color:#82BFFF;" @click="openStation(scope.row.station)">{{scope.row.station}}</span>                                 
                                </template>
                            </el-table-column>
                            <el-table-column prop="mediaGrade" align="center" width="100px" sortable label="媒体等级"></el-table-column> 
                            <el-table-column prop="name" align="center" sortable label="套餐名称">
                                <template v-slot="scope">
                                    <!-- <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                                        <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                                        <div v-else>{{item.mediaCode}}</div>
                                    </div> -->
                                    <span style="cursor:pointer;color:#82BFFF;" @click="openPackage(scope.row.groupId)">{{scope.row.name}}</span>
                                    <!-- <el-button type="text" @click="openPackage(scope.row.groupId)">{{scope.row.name}}</el-button> -->
                                </template>
                            </el-table-column>                                         
                            <el-table-column prop="typeName" align="center" width="80px" sortable label="媒体形式"></el-table-column>
                            <el-table-column prop="position" align="center" sortable label="位置"></el-table-column>
                            <el-table-column prop="totalCount" align="center" width="50px" sortable label="数量"></el-table-column> 
                            <el-table-column prop="mediaCode" align="center"  sortable label="状态">
                                            <template v-slot="scope">
                                                <span  :style="scope.row.statusName=='无效'?'color:red':''" >{{scope.row.statusName}}</span>
                                                
                                            </template>
                                        </el-table-column>
                        </el-table>
                        <el-pagination
                                        @size-change="handleProductSizeChange"
                                        @current-change="handleProductCurrentChange"
                                        :current-page="productGroup.currentPage"
                                        :page-sizes="[20,50, 100]"
                                        :page-size="productGroup.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="tableData.length">
                        </el-pagination>
                    </el-row>
                    </div>
                    <div class="button-footer">                      
                        <span >
                            <el-button @click="close()">关闭</el-button>
                            
                        </span>                    
                    </div>
                <!-- </el-dialog> -->
            <el-dialog title="套餐明细" v-model="viewPackageVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                <package-detail @close="viewPackageVisible=false" :package-data="packageMediaList"/>
                    <!-- <div>
                        <el-table
                            :data="packageMediaList.slice((mediaGroup.currentPage-1)*mediaGroup.pageSize,mediaGroup.currentPage*mediaGroup.pageSize)"
                            border
                            :header-cell-style="{background:'#eef1f6',color:'#606266'}"
                            style="width: 100%;margin-top: 20px;font-size: x-small;">		                           
                            <el-table-column prop="station" align="center" sortable label="车站"></el-table-column>
                            <el-table-column prop="mediaCode" align="center" sortable label="资源编号">
                                <template v-slot="scope">
                                    <el-button v-if="scope.row.imgList &&  scope.row.imgList.length>0" type="text" @click="openImage(scope.row.imgList)">{{scope.row.mediaCode}}</el-button>
                                    <span v-else>{{scope.row.mediaCode}}</span>
                                </template>
                            </el-table-column>                                                            
                            <el-table-column prop="typeName" align="center" sortable label="媒体形式"></el-table-column>
                            <el-table-column prop="position" align="center" sortable label="位置">
                                <template v-slot="scope">
                                    <span>{{scope.row.position}}/{{scope.row.address}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="adSize" align="center" sortable label="尺寸(㎡)"></el-table-column> 
                            <el-table-column prop="adArea" align="center" sortable label="面积(㎡)"></el-table-column> 
                        </el-table>
                        <el-pagination
                                        @size-change="handleMediaGroupChange"
                                        @current-change="handleMediaGroupChange"
                                        :current-page="mediaGroup.currentPage"
                                        :page-sizes="[20,50, 100]"
                                        :page-size="mediaGroup.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="packageMediaList.length">
                        </el-pagination>

                        
                    </div>
                    <div class="button-footer">                      
                        <span >
                            <el-button @click="viewPackageVisible = false">关闭</el-button>
                        </span>                    
                    </div> -->
            </el-dialog>

            <!-- <el-dialog title="资源图片" v-model="viewImageVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                    <div>
                        <el-carousel :interval="4000" type="card" height="400px">
                            <el-carousel-item v-for="item in imageList" :key="item">
                            <el-image :src="item" >
                                <div class="image-slot">
                                    加载中<span class="dot">...</span>
                                </div>
                            </el-image>
                            </el-carousel-item>
                        </el-carousel>
                    </div>
                    <div class="button-footer">                      
                        <span >
                            <el-button @click="viewImageVisible = false">关闭</el-button>
                        </span>                    
                    </div>
            </el-dialog> -->

            <el-dialog title="设置投放计划" v-model="setPlanVisible" width="40%" @open="open()"
                                        :modal-append-to-body='false'
                                        :close-on-click-modal='false'
                                        v-if="setPlanVisible"
                                        append-to-body>
                <set-plan @close="setPlanVisible=false" :cur-item="curItem" :table-data="planData"/>
                                        <!-- :group-media-list="groupDetailMediaList" -->
            </el-dialog>

            <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="viewStationVisible"
                                    append-to-body>
                <div style="height:2800px;">
                    <station @close="viewStationVisible=false" :station="station"/>
                </div>
        </el-dialog>

       </div>       

    <!-- </el-dialog> -->
</template>

<script>
import { mapGetters } from "vuex";
// import SmartResult from './SmartResult.vue';
import {SaveProjectSmart,ProjectExport} from "@/api/operate"
import {getToken,getImagePath} from "@/utils/auth";
import SetPlan from '@/views/Business/Smart/SetPlan';
import PackageDetail from '@/views/Business/Smart/PackageDetail';
// import SelectMedia from './SelectMedia.vue';
// import SelectProduct from './SelectProduct.vue';
// import SelectMediaPackage from './SelectMediaPackage.vue';
import Station from '@/views/Business/Station';

export default {
    name: 'baseform',
    props:['curItem','tableData'],
    components:{SetPlan,PackageDetail,Station},
    computed: {...mapGetters(['GetPackageMediaList','GetGroupDetail'])},
    data() {
        return {
           productGroup:{
                // 当前页
                currentPage: 1,
                // 每页多少条
                pageSize: 20,
            },
            // mediaGroup:{
            //     // 当前页
            //     currentPage: 1,
            //     // 每页多少条
            //     pageSize: 20,
            // },
 
            viewPackageVisible:false,
            packageMediaList:[],
            // imageList:[],
            // viewImageVisible:false,
            setPlanVisible:false,
            planData:[],
            station:'',
            viewStationVisible:false,
        };
    },
    
    async mounted() {
        //   await this.$store.dispatch('operate/areaStationList');
        //   await this.$store.dispatch('operate/categoryList');
        //   await this.$store.dispatch('operate/mediaTypeList');
        //   await this.$store.dispatch('operate/packageList');
        // this.getDetail();        

        // this.tableData=this.groupMediaList;
    },

    watch:{
        curItem(newValue){
            console.log('newValue',newValue)
            if (newValue ){
            //    this.getDetail();     
                // console.log('tbdata',this.tableData)       
            }
                
        }
    },


    methods: {
        // async getDetail(){
        //      await this.$store.dispatch('operate/GetGroupDetail',{groupId:this.curItem.groupId}).then(()=>{   
        //         // console.log('item',this.GetGroupDetail[1])
        //         this.tableData=this.GetGroupDetail[1];  
        //         // this.prodCostByMedia = this.GetGroupDetail[2][0].costs;
        //         // this.setDetailDialogVisible=true;      
        //     })   
        // },

        async openPackage(val){
            await this.$store.dispatch('operate/GetPackageMediaList',{groupId:val}).then(()=>{   
                var obj=[];
                var path = getImagePath();
                // console.log('aa',this.GetPackageMediaList)
                this.GetPackageMediaList[1].forEach(element => {
                    var imgObj=[];
                    if (element.images){
                        var imgArr=element.images.split(';');                    
                        if (imgArr && imgArr.length>0){
                            imgArr.forEach(ele2 => {
                                imgObj.push(path+ele2);
                            });                
                        }
                    }                    
                    element.imgList=imgObj;
                    obj.push(element);
                });
                this.packageMediaList = obj;
                console.log('aa',this.packageMediaList)
                this.viewPackageVisible=true;      
            })   
        },

        async onSetPlan(item){
            // this.curItem=item;
            
            // this.curItem=item;
            await this.$store.dispatch('operate/GetGroupDetail',{groupId:this.curItem.groupId}).then(()=>{   
                this.planData=this.GetGroupDetail[1];   
                this.setPlanVisible=true;       
            })   

        },

        // openImage(items){
        //     this.imageList=items;
        //     this.viewImageVisible=true;
        // },

        // ViewImage(item){
        //     this.imageList = item;
        //     this.viewImageVisible=true;
        // },

        open(){
            // console.log('do open')
            this.$nextTick(() => {
            //  执行echarts方法
            
            })
        },

        openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

        // 每页多少条
        handleProductSizeChange(val) {
            this.productGroup.pageSize = val;
        },
        // 当前页
        handleProductCurrentChange(val) {
            this.productGroup.currentPage = val;
        },
        // 每页多少条
        handleMediaGroupChange(val) {
            this.mediaGroup.pageSize = val;
        },
        // 当前页
        handleMediaGroupChange(val) {
            this.mediaGroup.currentPage = val;
        },

        close(){
            // this.$parent.closeDetail();
            this.$emit('close');
        },
    }
};
</script>

<style scoped>
.sub-title{
    font-size: large;
    margin-top:4px;
}
.item-pre{
    font-size: larger;
}

.product-name{
    font-size: xx-large;
    margin-top:-10px;
}
.card-group{
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
}
.group-item{
    padding: 20px;
    margin-left: 10px;
    
}
.box-card {
    width: 480px;
    
  }
   .text {
    font-size: 14px;
  }

  .item {
    /* margin-bottom: 18px; */
    margin-top:18px;
  }

  /* .clearfix:before,
  .clearfix:after {
    display: table;
    content: "";
  }
  .clearfix:after {
    clear: both
  } */
 

.transition-box {
    margin-bottom: 10px;
    width: 200px;
    height: 100px;
    border-radius: 4px;
    background-color: #409EFF;
    text-align: center;
    color: #fff;
    padding: 40px 20px;
    box-sizing: border-box;
    margin-right: 20px;
  }

.schart-box {
    display: inline-block;
    margin: 20px;
}
.schart {
    width: 600px;
    height: 400px;
}
.content-title {
    clear: both;
    font-weight: 400;
    line-height: 50px;
    margin: 10px 0;
    font-size: 22px;
    color: #1f2f3d;
}


.grid-content {
    display: flex;
    align-items: center;
    flex-direction:column;
    /* height: 120px; */
}

.grid-subcontent {
    display: flex;
    align-items: center;
    height: 50px;
}

.grid-cont-right {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #999;
}

.grid-num {
    font-size: 15px;
    /* font-weight: bold; */
}

.grid-num-small {
    font-size: 16px;
    font-weight: bold;
}

.grid-subnum {
    font-size: 20px;
    font-weight: bold;
}

.grid-con-icon {
    font-size: 50px;
    width: 100px;
    height: 100px;
    text-align: center;
    line-height: 100px;
    color: #fff;
    border-radius: 50%;
    /* margin-left: 80px; */
}
.grid-subcon-icon{
    font-size: 30px;
    width: 50px;
    height: 50px;
    text-align: center;
    line-height: 50px;
    color: #fff;
}

.grid-con-0 .grid-subcon-icon {
    background: rgb(184, 223, 223);
}

.grid-con-0 .grid-num {
    color: rgb(184, 223, 223);
}

.grid-con-1 .grid-con-icon {
    background: rgb(45, 140, 240);
}

.grid-con-1 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-2 .grid-con-icon {
    background: rgb(100, 213, 114);
}

.grid-con-2 .grid-num {
    color: rgb(45, 140, 240);
}

.grid-con-3 .grid-con-icon {
    background: rgb(242, 94, 67);
}

.grid-con-3 .grid-num {
    color: rgb(242, 94, 67);
}

.grid-con-4 .grid-con-icon {
    background: rgb(132, 94, 67);
}

.grid-con-4 .grid-num {
    color: rgb(132, 94, 67);
}

.grid-con-5 .grid-con-icon {
    background: rgb(66, 94, 67);
}

.grid-con-5 .grid-num {
    color: rgb(66, 94, 67);
}

.grid-con-6 .grid-con-icon {
    background: rgb(66, 136, 67);
}

.grid-con-6 .grid-num {
    color: rgb(66, 136, 67);
}

.clearfix{
    margin-top: -5px;
}
.subtitle{
    font-weight: bolder;
    padding-right: 5px;
}


</style>