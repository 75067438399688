<template>
    <div>        
        <!-- <div class="container">
            <div  width="100%"> -->

                <el-steps :active="active" simple>
                    <el-step title="投放要求" icon="el-icon-edit" @click="setActive(0)"></el-step>
                    <el-step title="指标偏好" icon="el-icon-star-on" @click="setActive(1)"></el-step>                    
                    <el-step title="套餐" icon="el-icon-s-grid" @click="setActive(2)"></el-step>
                    <el-step title="结果预测" icon="el-icon-s-flag" @click="setActive(3)"></el-step>
                </el-steps>
                <div v-if="active==0">
                    <div style="margin-top:20px;"></div>
                    <el-form ref="form" :model="curForm" label-width="150px" label-position="left" style="margin-top:20px;">
                        <el-row>
                            <el-form-item label="投放预算" style="width:372px;">                            
                                <el-input style="width: 135px;margin-top:5px;" v-model="curForm.cost"></el-input>
                            </el-form-item>
                        </el-row>
                        <el-row>                                                  
                            <el-form-item label="投放周期" >    
                                <el-date-picker                                
                                    v-model="curForm.dateArray"
                                    style="width:262px;"
                                    type="daterange"
                                    unlink-panels
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期"                                   
                                    :clearable="false"
                                    :shortcuts="shortcuts"
                                    @change ="handleDateChange"
                                    >
                                </el-date-picker>
                            </el-form-item>  
                        </el-row>
                        <el-row>
                        <el-form-item label="每日投放频次" style="width:372px;">                            
                                <el-select @change="timesChanged" v-model="curForm.times"  placeholder="请选择每日投放频次">
                                    <el-option
                                    v-for="item in timesList"
                                    :key="item.value"
                                    :label="item.label"
                                    :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </div>
                
                <div v-if="active==1">
                    触达人口最多<br>
                    触达规模最大

                </div>
                <div v-if="active==2">
                    套餐1<br>
                    套餐2<br>
                    套餐3
                </div>
                <div v-if="active==3">
                    cpm:123<br>
                    触达:456<br>
                    OTS:111<br>
                </div>
                <div style="display:flex;justify-content:center;margin-top:20px;">
                <el-button v-if="active>0" style="margin-top: 12px;" @click="prev">上一步</el-button>
                <el-button v-if="active<3" style="margin-top: 12px;" @click="next">下一步</el-button>
                </div>
            </div>
        <!-- </div>
    </div> -->
</template>

<script>
import { mapGetters } from "vuex";
import SmartResult from '@/views/Business/SmartResult.vue';
import {SaveProjectSmart,ProjectExport} from "@/api/operate"
import {getToken} from "@/utils/auth";
import SelectMedia from '@/views/Business/SelectMedia.vue';
// import SelectProduct from './SelectProduct.vue';
import SelectMediaPackage from './../SelectMediaPackage.vue';
import {getLatestMonthDateArrayByDate} from "@/utils/time";


export default {
    name: 'baseform',
    components:{SmartResult, SelectMedia,SelectMediaPackage},
    computed: {...mapGetters(['packageList','ageList','genderList','targetList','outComeList','timesList'])},
    data() {
        return {
           active: 0,
           curForm:{
               dateArray:getLatestMonthDateArrayByDate({date: new Date(), delayNum: 2, formatValue: 'yyyy-M'}),
               times:100,
               cost:100,
           },
           timesList:[{label:'100次',value:100},{label:'200次',value:200},{label:'300次',value:300},{label:'400次',value:400},
            {label:'500次',value:500},{label:'600次',value:600},{label:'700次',value:700}],
            package4List:[],
        };
    },
    
    async mounted() {    
      await this.$store.dispatch('operate/packageList').then(()=>{          
           let index =0;
           let count = 4;		//两个一组
           let package4List = [];           
           for(let i=0;i<this.packageList.length;i++){
             index = parseInt(i/count);
             if (package4List.length <= index) {
                 package4List.push([]);
             }
             package4List[index].push(this.packageList[i])
           }
           this.package4List = package4List;
        //    console.log('aa',package4List)
      });
      await this.$store.dispatch('operate/ageList');
      await this.$store.dispatch('operate/genderList');
      await this.$store.dispatch('operate/targetList');
      await this.$store.dispatch('operate/outComeList');

   
    },

   
    methods: {
        next() {
            this.active++;
        },

        prev(){
            this.active--;
        },

        setActive(val){
            this.active=val;
        },


    }
};
</script>

<style scoped>

</style>