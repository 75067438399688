<template>
  <div>
    <div class="container">
      <el-form  ref="form" :model="searchForm" label-width="80px" style="margin-top:20px;" label-position="left">
          <el-row>
            <search-old @onchange="changeTag" :type="1" @setfinished="setFinished"/>
          </el-row>
      </el-form>
    </div>
    <div>
      <el-divider>待选资源</el-divider>
      <el-table
        ref="tableObj" 
        :data="SearchMediaList"
        border
        :header-cell-style="{ background: '#eef1f6', color: '#606266' }"
        style="width: 100%; margin-top: 10px; font-size: x-small"
        :row-key="getRowKey" stripe 
		    @selection-change="handleSelectionChange"
      >
        <el-table-column type="selection" reserve-selection width="55"></el-table-column>
        <!-- <el-table-column prop="station" align="center" sortable label="车站"></el-table-column> -->
        <el-table-column prop="medias" align="center"   width="220"  sortable label="站点">
          <template v-slot="scope">  
            <div v-if="scope.row.stationId!=='0'" style="color: #409EFF;cursor: pointer;"  @click="openStation(scope.row.station)"><span style="font-size:x-large">{{scope.row.station}}</span></div>
            <div v-else><span style="font-size:large">{{scope.row.station}}</span></div>  
              <!-- <div style="color: #409EFF;cursor: pointer;"  @click="openStation(scope.row.station)"><span style="font-size:x-large">{{scope.row.station}}</span></div> -->
          </template>
        </el-table-column>
        <el-table-column prop="position" align="center" sortable label="位置"></el-table-column>
        <el-table-column v-if="dataType==1" prop="mediaCode" align="center" sortable label="媒体编码">
          <!-- <template v-slot="scope">  
            aa{{ scope.row.mediaCode.substring(scope.row.mediaCode.length-1,scope.row.mediaCode.length-1) }}bb
              <span>{{ scope.row.mediaCode.substring(scope.row.mediaCode.length-1,scope.row.mediaCode.length-1)=='|'?scope.row.mediaCode.substring(0,scope.row.mediaCode.length-1):scope.row.mediaCode }}</span>
          </template>  -->
        </el-table-column>
        <el-table-column v-if="dataType==2" prop="name" align="center" width="130" sortable label="套餐名称"></el-table-column>
        <!-- <el-table-column v-if="dataType==2" prop="medias" align="center"   sortable label="媒体资源">
            
        </el-table-column> -->
        <el-table-column prop="medias" align="center"   sortable label="媒体资源(尺寸m*m/面积㎡)">
          <template v-slot="scope">
              <!-- <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                  <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                  <div v-else>{{item.mediaCode}}</div>
              </div> -->
              <div v-if="scope.row.collapse==-1"><!-- 无需折叠 -->
                  <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                      <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                      <div v-else>{{item.mediaCode}}</div>
                  </div>
              </div>
              <div v-else-if="scope.row.collapse==1"><!-- 折叠状态 -->
                  <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                      <div v-if="index<4">
                          <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                          <div v-else>{{item.mediaCode}}</div>                                                
                      </div>
                  </div>
                  <i class="el-icon-arrow-down" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
              </div>
              <div v-else><!-- 展开状态 -->
                  <div class="group-item" v-for="(item,index) in scope.row.mediaInfos" :key="index">
                      <div v-if="item.images.length>0" style="color: #409EFF;cursor: pointer;" @click="ViewImage(item.images)">{{item.mediaCode}}</div>
                      <div v-else>{{item.mediaCode}}</div>                                            
                  </div>
                  <i class="el-icon-arrow-up" style="cursor:pointer;" @click="onCollapse(scope.row)"></i>
              </div>
          </template>
      </el-table-column>

        <el-table-column prop="typeName" align="center" width="100" sortable label="媒体形式"></el-table-column>
        <el-table-column v-if="dataType==1" prop="address" align="center" sortable label="具体位置"></el-table-column>
        <el-table-column v-if="dataType==2" prop="totalCount" align="center" width="55" sortable label="数量"></el-table-column>
        <el-table-column v-if="dataType==1" prop="areaPercent" align="center" sortable label="面积占比"></el-table-column>
        <el-table-column v-if="dataType==1" prop="adSize" align="center" sortable label="广告尺寸"></el-table-column>       
        <el-table-column v-if="dataType==1" prop="adArea" align="center" sortable label="广告面积"></el-table-column>

      </el-table>
      <!-- <div style="display:flex;justify-content:space-between ;">         
          <el-pagination
                      @size-change="handleMediaSizeChange"
                      @current-change="handleMediaCurrentChange"
                      :current-page="mediaGroup.currentPage"
                      :page-sizes="[20,50, 100]"
                      :page-size="mediaGroup.pageSize"
                      layout="total, sizes, prev, pager, next, jumper"
                      :total="dataTableList.length">
          </el-pagination>           
      </div> -->
      
    </div>
    <el-dialog title="车站介绍" v-model="viewStationVisible" width="80%"  @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    v-if="viewStationVisible"
                                    append-to-body>
                    <div style="height:2800px;">
                        <station @close="viewStationVisible=false" :station="station"/>
                    </div>
                </el-dialog>   

                <el-dialog title="资源图片" v-model="viewMediaVisible" width="40%" @open="open()"
                                    :modal-append-to-body='false'
                                    :close-on-click-modal='false'
                                    append-to-body>
                    <media-detail @close="viewMediaVisible=false" :image-data="imageList"/>
                </el-dialog>

  </div>
</template>

<script>
import { number } from 'echarts';
import { mapGetters } from "vuex";
import SearchOld from '@/components/SearchOld';
import Station from '@/views/Business/Station';
import MediaDetail from '@/views/Business/Smart/MediaDetail';

export default {
  name: "baseform",
  components:{SearchOld,Station,MediaDetail},
  props: {
			typeId: {
				type: Number,
				default: 0  //0代表不限制类型
			},
      dataType:{
        type:Number,
        default:1
      }
  },
  model: {
		prop: 'form',
		event: 'change'
  },
  async created() {
      this.clearSearchList();
  },

  computed: {
    ...mapGetters([
      "mediaTypeList",
      "positionList",
      "GetGroupList",
      "areaStationList",
      "GetStationPositionMediaList",
      "GetProductMediaList",
      "GetGroupList_ByTag",
    ]),
  },
  data() {
    return {
      form: {
        stationIdArray: [],
        ptArray: [],
        selectedList:[],
        typeArray:[],
        key:'',
      },
      SearchMediaList: [],
      ddlStation:[],

      props: { multiple: true },
      finished:false,

      station:'',
      isCityAnd:false,
      viewStationVisible:false,
      viewMediaVisible:false,
            imageList:[],
    };
  },

  async mounted() {
    await this.$store.dispatch("operate/areaStationList");
    // console.log('areaStationList',this.areaStationList)
    await this.$store.dispatch("operate/positionList");

    this.ddlStation = this.areaStationList;
    var index = this.ddlStation.findIndex(item =>{
　　　　　if(item.value=='全国'){
　　　　　　　　return true
　　　　　}
　　})
// 　　this.ddlStation.splice(index,1)

  },

  methods: {
    changeTag(obj,key,station,isCityAnd){
            // this.tagIds=obj;
            // this.key=key;
            // this.station=station;
            // this.isCityAnd=isCityAnd;
            // this.onMediaGroupQuery();
            
        this.tagIds=obj;
        this.key=key;
        this.station=station;
        this.isCityAnd=isCityAnd;
        this.$refs.tableObj.clearSelection();
        if ((obj=='' || obj== ";") && key=='' && station==''){
            this.SearchMediaList=[];
        }else{
            this.onMediaGroupQuery();
        }
    },

    openStation(station){
            this.station=station;
            this.viewStationVisible=true;
        },

    ViewImage(item){
            this.imageList = item;
            this.viewMediaVisible=true;
        },
     onCollapse(item){
            console.log('collapse')
            if (item.collapse==1){
                item.collapse=0;
            }else{
                item.collapse=1;
            }
        },

      //通知子组件已完成，可以显示父组件中的table
        setFinished(){
            this.finished=true;
        },

    //查询资源套餐
        async onMediaGroupQuery(){            
            await this.$store.dispatch('operate/GetGroupList_ByTag',{tagIds:this.tagIds,key:this.key,station:this.station,isCityAnd:this.isCityAnd}).then(()=>{
                this.SearchMediaList = this.GetGroupList_ByTag;
            })
        },


    clearSearchList(){
      this.SearchMediaList=[];
    },

    async onMediaQuery() {        
        console.log('this.typeId',this.typeId,this.dataType)

        const {stationIdArray,ptArray,typeArray,key} =  this.form;
        
        if (stationIdArray.length<1){
            this.$message.success('请选择车站');
            return;
        }
        var posIds='';
        if (ptArray.length>0)
            posIds = ptArray.join(',');


        var typeIds=typeArray;
        var sid = '';//stationId[2];
        stationIdArray.forEach(element => {
          if (sid==''){
            sid=element[2];
          }else{
            sid=sid+','+element[2];
          }
        });
        if (this.typeId!==0){
          typeIds=this.typeId+'';
        }
        // console.log('stationId',sid)
 
        // console.log('aa',typeIds)
        if (this.dataType==1){
          await this.$store.dispatch('operate/GetStationPositionMediaList',{stationId:sid,typeIds,posIds,key}).then(()=>{
              this.SearchMediaList = this.GetStationPositionMediaList;
              // console.log('this.$refs.tableObj',this.$refs.tableObj)
              this.$refs.tableObj.clearSelection();
          })
        }else{
          // console.log('aa',typeIds)
          await this.$store.dispatch('operate/GetProductMediaList',{stationId:sid,typeIds,posIds,key}).then(()=>{
              this.SearchMediaList = this.GetProductMediaList;
              // console.log('this.$refs.tableObj',this.$refs.tableObj)
              this.$refs.tableObj.clearSelection();
          })
        }

    },

    handleSelectionChange(val) {				
      this.form.selectedList = val;				
      this.SelectEdEmit();
    },

    SelectEdEmit() {
      const obj = {...this.form};
      console.log('obj',obj);
      this.$emit('change', obj);
    },

    getRowKey(row) {
      return row.mediaId;
    },

    open() {
      console.log("do open");
      this.$nextTick(() => {
        //  执行echarts方法
      });
    },
  },
};
</script>

<style scoped>
</style>